import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetRequisitionDocument = gql`
  query GetRequisition($requisitionId: Int) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        editableForCurrentUser
        sourceApplication
        status
        requisitionNumber
        reference
        originName
        originId
        originType
        advanced
        currency
        creator {
          id
          fullName
        }
        purchaser {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        createdAt
        totalValueExcludingTax
        totalTaxValue
        totalValue
        nextApprovingAgentsNames
        nextApprovingUsers {
          id
          fullName
        }
        expectedDeliveryDate
        sendToSupplier
        awaitingMyApproval
        commentsCount
        filesCount
        taxCodeInfo
        taxCodeId
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        approvalWorkflow {
          id
          title
        }
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        requestor {
          id
          fullName
          createdAt
        }
        suppliers {
          edges {
            node {
              id
              name
            }
          }
        }
        receivingDocuments {
          edges {
            node {
              id
              receivingDocumentNumber
            }
          }
        }
        invoices {
          edges {
            node {
              id
              invoiceNumber
            }
          }
        }
        purchaseOrders {
          edges {
            node {
              id
              sentDate
              purchaseOrderNumber
              supplier {
                id
                name
              }
              totalValue
              statusEnum
              sendToSupplier
            }
          }
        }
        approvalWorkflow {
          id
          title
        }
        nextApprovalUsers {
          id
          fullName
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetRequisitionQuery, Types.GetRequisitionQueryVariables>
