import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from '@/App'
import '@/index.css'
import {
  ACCESS_GRAPHQL_API,
  INVENTORY_GRAPHQL_API,
  PURCHASE_PLUS_GRAPHQL_API,
  PURCHASING_GRAPHQL_API,
} from '@/modules/shared/constants'
import reportWebVitals from '@/reportWebVitals'

window.addEventListener('error', (event) => {
  // The chunks all have UUIDs in them and are different each time to avoid caching old versions of the app.
  // So when we redeploy while users are still using the app, this error will be thrown when users navigate between pages because it couldn't fetch the chunks.
  // The solution to this is to forcefully reload the user's session when this error is thrown.
  // In other words, whenever this error occurs, it means there is a new deployment of the app.

  const regexes = [
    /Failed to fetch dynamically imported module/,
    /Failed to load module script/,
    /Unable to preload CSS/,
    /'text\/html' is not a valid JavaScript MIME type\./,
    /error loading dynamically imported module/,
  ]

  if (event.message && regexes.some((regex) => regex.test(event.message))) {
    window.location.reload()
  }
})

Sentry.init({
  release: 'app-purchase-plus@' + import.meta.env.VITE_APP_VERSION,
  environment: import.meta.env.VITE_APP_BUILD_ENV,
  dsn: import.meta.env.VITE_APP_DSN_VAR,
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      sentry: true,
      xhr: false,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
      maskAllInputs: false,

      // config to show request and reponse on prd
      networkDetailAllowUrls: [
        PURCHASING_GRAPHQL_API,
        PURCHASE_PLUS_GRAPHQL_API,
        INVENTORY_GRAPHQL_API,
        ACCESS_GRAPHQL_API,
      ],
    }),
  ],
  tracesSampleRate: 1.0,

  // setup session replay rates
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    // Since, unauthorized error is already handled the dialog, we can ignore it.
    /Unauthorized/,

    /Failed to fetch dynamically imported module/,
    /Failed to load module script/,
    /Unable to preload CSS/,
    /'text\/html' is not a valid JavaScript MIME type\./,
    /error loading dynamically imported module/,

    // Loss of internet connection
    /\(Status code undefined\)/,
    /Cannot read properties of undefined \(reading 'data'\)/,
    /undefined is not an object \(evaluating 'e\.data'\)/,
    /UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,

    // ignore from third party libraries
    'ResizeObserver loop limit exceeded',
    "Can't find variable: msDiscoverChatAvailable",
    'UnhandledRejection: Non-Error promise rejection captured with value: undefined',
    'E.postMessage is not a function',
    // ignore Safari extensions
    /webkit-masked-url/,

    // ignore password manager induced unhandledRejections https://github.com/getsentry/sentry-javascript/issues/3440
    /UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
  ],
  denyUrls: [
    // Regex pattern to match 'preview-purchaseplus.com' in URLs will be denied.
    /preview-purchaseplus\.com/,

    // ignore Chrome extensions, ref: https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,

    // ignore google trackers
    /googlesyndication\.com/i,
  ],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
