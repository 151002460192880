import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetPricedCatalogueDocument } from '@/graphql/purchase-plus/generated/getPricedCatalogue.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

export const useGetPricedCatalogue = () => {
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()

  const { data, error, networkStatus, refetch } = useQuery(GetPricedCatalogueDocument, {
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
    },
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    skip: !pricedCatalogueId,
    notifyOnNetworkStatusChange: true,
  })

  const pricedCatalog = data?.pricedCatalogue

  const loadingStates = checkNetworkStatus(networkStatus)

  return {
    refetch,
    pricedCatalog,
    loadingStates,
    error,
  }
}
