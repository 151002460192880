import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetMySuppliersDocument = gql`
  query GetMySuppliers(
    $after: String
    $before: String
    $enabled: Boolean
    $first: Int
    $isVerified: Boolean
    $last: Int
    $preferredSupplier: Boolean
    $searchText: String
    $sort: String
    $accountsPayableSystemId: Int
    $filters: SuppliersQueryFiltersInput
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      suppliers(
        after: $after
        before: $before
        enabled: $enabled
        first: $first
        isVerified: $isVerified
        last: $last
        preferredSupplier: $preferredSupplier
        searchText: $searchText
        sort: $sort
        filters: $filters
      ) {
        edges {
          node {
            id
            name
            isVerified
            businessNumber
            email
            telephone
            creditApplicationUrl
            supplierRelationship {
              id
              isEnabled
              isEnabledBySupplier
              status
              preferredSupplier
              supplierNumber
              supplierLocationCode
              isSelfManagedCataloguesAllowed
            }
          }
        }
        pageInfo {
          ...PurchasePlusPageInfo
        }
      }
    }
  }
  ${PurchasePlusPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetMySuppliersQuery, Types.GetMySuppliersQueryVariables>
